@import '_colors.scss';
@import '_fonts.scss';
@import '_mixins.scss';

.frame {
  display: grid;
  position: absolute;
  pointer-events: none;
  width: 100%;
  height: 100vh;
  padding: 1.5rem 3rem;
  top: 0;
  left: 0;
  align-content: space-between;
  grid-template-columns: 33% 33% 33%;
  grid-template-rows: auto auto;
  grid-template-areas: '... ... ..' 'copyright instructions links';
  z-index: 1000;
  font-size: 12px;
}

.frame__copyright {
  text-align: start;
  grid-area: copyright;
}

.frame__instructions {
  text-align: center;
  grid-area: instructions;
}

.frame__links {
  pointer-events: auto;
  text-align: end;
  grid-area: links;

  a {
    img {
      margin-left: 4px;
    }
  }
}