@import '_colors.scss';
@import '_fonts.scss';
@import '_mixins.scss';

.music-page,
.contact-page,
.about-page,
.projects-page {
  @include display--flex-column;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;

  &__blurb {
    @include display--flex-row;
    padding: 1rem;
  }
}

.about-page {
  > * {
    width: 100%;
    padding: 1rem;
  }

  &__skills {
    &__container {
      @include display--flex-row-wrap;
      padding: 0;
    }

    &--item {
      @include display--flex-column;
      min-width: 8rem;
      margin: 1rem;
      font-size: 16px;
    }
  }

  &__proficiencies,
  &__languages {
    &__container {
      @include display--flex-row-wrap;
      padding-bottom: 2rem;
      background-color: $color--panel__header-background;
      @include panel__border;
    }

    &--item {
      @include display--flex-column;
      margin: 2rem;
      min-width: 4rem;
      max-width: 4rem;
      min-height: 4rem;
      max-height: 4rem;

      span {
        text-align: center;
      }

      img {
        margin-bottom: 0.5rem;
        min-width: 4rem;
        max-width: 4rem;
        min-height: 4rem;
        max-height: 4rem;
        object-fit: contain;
      }
    }
  }

  &__attributes {
    &__container {
      @include display--flex-row-wrap;
      padding: 1rem;
      background-color: $color--panel__header-background;
      @include panel__border;
    }

    &--item {
      @include display--flex-column;
      min-width: 8rem;
      margin: 1rem;
      font-size: 16px;
    }
  }

  &__achievements {
    &__container {
      @include display--flex-row-wrap;

      > *:not(:last-child) {
        margin-bottom: 1rem;
      }

      > :nth-child(odd) {
        margin-right: 1rem;
      }
    }

    &--item {
      width: calc(50% - 0.5rem);
      padding: 1rem;
      background-color: $color--panel__header-background;
      @include panel__border;
    }
  }

  &__history,
  &__education {
    &__container {
      @include display--flex-column;

      > *:not(:last-child) {
        margin-bottom: 1rem;
      }
    }

    &--item {
      padding: 1rem;
      background-color: $color--panel__header-background;
      @include panel__border;
    }
  }
}

.contact-page {
  justify-content: center;
  align-items: center;

  &__badges {
    @include display--center;

    > * {
      margin: 0.5rem;
    }
  }
}

.music-page {
  justify-content: center;
}

.projects-page {
  &__project {
    &--container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 2.5rem 2.5rem 2.5rem;
      padding: 1.5rem;
      background-color: $color--panel__header-background;
      @include panel__border;
    }

    &--content {
      text-align: left;
    }

    &--images {
      @include display--flex-row-wrap;
      $img-width: 200px;
      $img-height: 110px;

      > * {
        margin: 1rem;
      }

      img { 
        min-width: $img-width;
        max-width: $img-width;
        min-height: $img-height;
        max-height: $img-height;
        object-fit: cover;
        pointer-events: all;
      }
    }

    &--links {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
  }
}

.unsupported-page {
  @include display--flex-column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 1rem;
  @include font-default;
  @include panel__background--diagonal;
  background-color: $color--tertiary;

  > * {
    text-align: center;
    margin: 1rem;
  }

  img {

    max-width: 200px;
  }
}