@import '_colors.scss';
@import url("https://fonts.googleapis.com/css?family=Roboto+Mono");
@import url("https://fonts.googleapis.com/css?family=Press+Start+2P");

$font-family--default: 'Roboto Mono', serif;
$font-family--pixels: 'Press Start 2P', serif;
$font-family--w95fa: 'w95fa'; 
$font-weight--thin: 200;
$font-weight--regular: 400;
$font-weight--bold: 600;
$line-height--default: 1.6em;
$font-size--default: 12px;
$letter-spacing--default: 1px;
$font-color--primary: $color--primary;

@font-face {
  font-family: 'w95fa';
  src: url('~/public/fonts/W95FA/w95fa.woff2');
}

@mixin font-default {
  font-family: $font-family--default;
  font-weight: $font-weight--regular;
  -webkit-font-smoothing: antialiased;
  line-height: $line-height--default;
  font-size: $font-size--default;
  letter-spacing: $letter-spacing--default;
  color: $font-color--primary;
}

h1, h2, h3, h4, h5 {
  font-weight: $font-weight--thin;
  line-height: 1em;
}